import React from 'react'
import Layout from '../components/layout.js'
import Seo from '../components/seo.js'
import bgImg from '../images/meeting-blue.png'
import headerImg from '../images/meeting.png'
import msGlass from '../images/mag-str.png'

import './business.css.less'
import BusinessContent from './partials/businesscontent.js'

function Business() {
	return (
		<Layout>
			<Seo title="Business" />
			<div className="business-container">
				<header className="v-header bus-container">
					<div className="bus-image-wrap">
						<img
							src={bgImg}
							alt=""
							className="bus-image"
							style={{ backgroundSize: 'contain' }}
						/>
						<img src={msGlass} alt="" className="bus-msGlass-r" />
					</div>
					<div
						className="bus-header-content"
						style={{
							backgroundImage: `url(${headerImg})`,
							backgroundSize: 'cover'
						}}
					>
						<div className="bus-header-title">
							<h5>BUSINESS</h5>
						</div>
					</div>
				</header>
				<BusinessContent />
			</div>
		</Layout>
	)
}

export default Business
