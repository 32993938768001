import React from 'react'
import busIcon1 from '../../images/checkfile.png'
import busIcon2 from '../../images/cloud.png'
import mslantGlass from '../../images/mag-slant.png'
import { Link } from 'gatsby'

function BusinessContent() {
	return (
		<div id="business-content">
			<div className="section-b">
				<div className="ui container">
					<div className="ui equal width grid stackable">
						<div className="six wide column">
							<h3 className="text-highlight">
								Convenient <br />
								Legal Resource <br />
								for Business
							</h3>
							<div className="ui fitted divider"></div>
							<br />
							<span className="business-description">
								Get reliable and <br /> customizable legal and <br /> business
								forms and
								<br />
								research assistance <br />
								anywhere
							</span>
						</div>
						<div
							className="four wide column description"
							//
						>
							<img src={busIcon1} alt="" className="bus-icon"></img>
							<br />
							<br />

							<span className="text-highlight">
								Get legal research <br /> assistance on topics <br />
								about labor, <br />
								corporate
								<br />
								governance,
								<br />
								commercial transactions and <br />
								taxation among others
							</span>
						</div>
						<div
							className="column description"
							// style={{ padding: '5% 10% 5% 0' }}
						>
							<img src={busIcon2} alt="" className="bus-icon"></img>
							<br />
							<br />

							<span className="text-highlight">
								A cloud-based <br /> platform means you <br /> can access
								important <br /> business and <br /> legal forms from <br />
								any device
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="section-b-1">
				<div className="ui container">
					<div className="ui equal width stackable grid">
						<div className="column">
							<h3 className="text-description text-highlight">
								Get <i>MylegalWhiz</i>
								<br /> for your business
							</h3>
						</div>
						<div className="column">
							<Link to="/pricing">
								<br></br>
								<span className="text-subscribe text-highlight">
									SUBSCRIBE NOW ▼
								</span>
							</Link>
						</div>
						<img src={mslantGlass} alt="" className="bus-mslantGlass" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default BusinessContent
